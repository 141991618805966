@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }

  & footer {
    display: none;

    @media (--viewportLarge) {
      display: block;
    }
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBarWithCTAEnabled {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0 8px;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  @media (--viewportMedium) {
    flex-direction: row;
    border-radius: var(--borderRadiusMedium);
  }
}

.actionBarForProductLayout {
  margin: 0;
  width: 100%;

  &:nth-child(even) {
    margin-top: 24px;
  }

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
  border-radius: var(--borderRadiusMedium);
}

.actionBarCTA {
  composes: buttonSmall from global;
  display: inline-block;
  width: 100%;
  margin: 10px 0px 20px 0px;

  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    width: auto;
    margin: 20px 0 20px 15px;
  }
}

.linkContainer {
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 8px;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px 0 0;

  @media (--viewportMedium) {
    margin: 24px 24px 24px 0;
  }

  &.CTAEnabled {
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin: 24px 0 0px 0;
    }
  }
}

.noShrink {
  display: flex;
  flex-shrink: 0;
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  margin: 23px 24px 11px 0px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
  }

  &.CTAEnabled {
    margin: 24px 0 0 0;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: buttonSmall from global;

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);
  padding: 24px;

  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 386px - 120px);
    margin: 0 30px;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  align-self: flex-start;

  @media (--viewportLarge) {
    display: block;
    background-color: var(--colorWhite);
    padding: 24px;
    box-shadow: var(--boxShadow);
    max-width: 386px;
    width: 100%;
    flex-shrink: 0;
  }
}

.mainContentWrapper {
  padding: 30px 24px;

  @media (--viewportLarge) {
    padding: 50px 24px;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
  }
}

.orderPanelTitle {
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);
  width: 100%;
  margin: 0;
  font-size: 18px;
  line-height: 120%;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0;
  display: flex;
  margin-bottom: 14px;
}

.location {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 4px;
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }
}

.detailSecHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  & .orderPanelTitle {
    padding-right: 15px;
    margin-bottom: 6px;
  }

  & .listingId {
    display: inline-flex;
    white-space: nowrap;
    border-radius: var(--borderRadiusMedium);
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    font-size: 13px;
    line-height: 120%;
    padding: 6px 10px;
  }
  & .sectionText {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: h4 from global;
  font-size: 18px;
  line-height: 120%;
  color: var(--colorGrey900);
  width: 100%;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }

  & > svg {
    & path {
      stroke-width: 2px;
    }
  }
}

.cancellationSec {
  border-top: solid 1px var(--colorGrey100);
  border-bottom: solid 1px var(--colorGrey100);
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  & .sectionHeading {
    margin-bottom: 0;
  }
  & .cancellationText {
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    color: var(--colorGrey800);
    line-height: 150%;
    margin-top: 10px;

    & p {
      margin: 0px;
    }
  }
}
.otherDetails {
  display: flex;
  flex-wrap: wrap;
  padding: 14px 0 24px;
  & .sectionText {
    width: 100%;
    margin-bottom: 6px;
    @media (--viewportSmall) {
      width: calc(100% / 2);
    }
  }
}
.postSec {
  & .postedByContent {
    margin-top: 24px;

    & .postBlock {
      background-color: var(--colorGrey50);
      border-radius: var(--borderRadiusMedium);
      padding: 16px;
      border: solid 1px var(--colorGrey100);
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;

      @media (--viewportMedium) {
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      & .postOwner {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-bottom: solid 1px var(--colorGrey100);
        padding-bottom: 24px;
        margin-bottom: 24px;

        @media (--viewportMedium) {
          width: auto;
          border: none;
          flex-direction: row;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }

        & .postImg {
          position: relative;
          width: 100px;
          height: 100px;
          border-radius: var(--borderRadiusMedium);
          overflow: hidden;

          & > img {
            max-width: 100%;
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
        }

        & .postInfo {
          display: flex;
          flex-direction: column;
          padding-left: 10px;

          & > h3 {
            margin: 0;
            padding: 0;
            font-size: 18px;
            line-height: 120%;
            color: var(--colorGrey900);
          }

          & > span {
            line-height: 110%;
            font-size: 14px;
            background-color: var(--marketplaceColor);
            color: var(--colorWhite);
            padding: 2px 8px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            border-radius: var(--borderRadiusMedium);
            margin-top: 3px;
          }
        }
      }

      & .postTrailer {
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        & > h3 {
          margin: 0;
          padding: 0;
          font-size: 18px;
          line-height: 120%;
          color: var(--colorGrey900);
        }

        & > p {
          line-height: 110%;
          font-size: 14px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
}

.ingress {
  composes: p from global;
  margin: 0;
  padding: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0px;
  margin-bottom: 10px;
}

.sectionMap {
  padding: 0px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0px;
  margin-top: 42px;
  scroll-margin-top: calc(var(--topbarHeight) + 24px);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
    scroll-margin-top: calc(var(--topbarHeightDesktop) + 24px);
  }

  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  margin: 0;
  width: 100%;
  padding: 0;
  display: inherit;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    display: flex;
  }

  & .detailsRow {
    display: inline-flex;
    padding: 0;
    line-height: 100%;
    list-style: none;
    font-weight: var(--fontWeightRegular);
    margin: 10px 0 0 0;
    padding-right: 10px;
    width: 100%;

    @media (--viewportSmall) {
      width: calc(100% / 2);
    }
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
  color: var(--colorGrey900);
  margin-right: 4px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 100%;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }

  & > div {
    & > img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  position: relative;
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.claimListingWrapper {
  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.listingGallarySection {
}

.listingBreadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--colorWhite);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  padding: 10px 24px;

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  & .contentWidth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 0 auto;
    flex-direction: column;

    @media (--viewportMedium) {
      flex-direction: row;
    }
  }

  & .breadcrumbs {
    display: flex;
    align-items: center;

    & > a {
      color: var(--colorGrey700);
      font-size: 14px;
      line-height: 120%;
      margin: 0 40px 0 0;
      display: inline-flex;
      transition: var(--transitionStyle);
      position: relative;

      &:hover {
        transition: var(--transitionStyle);
        color: var(--colorBlack);
      }

      &::after {
        position: absolute;
        background-color: var(--colorGrey700);
        width: 4px;
        height: 4px;
        border-radius: 100px;
        display: inline-flex;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        right: calc(0% - 50%);
      }
    }

    & .current {
      font-size: 14px;
      font-weight: var(--fontWeightMedium);
      color: var(--marketplaceColor);
    }
  }

  & .listingActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 0 0;

    @media (--viewportMedium) {
      margin: 20px 0;
    }

    & > button {
      background-color: var(--colorWhite);
      padding: 10px 20px;
      font-size: 14px;
      line-height: 120%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: var(--borderRadiusMedium);
      transition: var(--transitionStyle);
      cursor: pointer;

      &:hover {
        transition: var(--transitionStyle);
        box-shadow: var(--boxShadow);
      }

      & > svg {
        margin-right: 10px;
        width: 14px;
        height: 14px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.sideNavigation {
  display: flex;
  flex-direction: column;
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  padding: 10px;
  align-self: start;
  box-shadow: var(--boxShadow);
  position: sticky;
  top: 108px;

  & .sideNavLink {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusMedium);
    padding: 2px;
    transition: var(--transitionStyle);

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover,
    &.active {
      background-color: var(--marketplaceColor);

      & > svg {
        & path {
          stroke: var(--colorWhite);
        }
      }
    }

    & > svg {
      width: 15px;
      height: 15px;
    }
  }

  & .progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin: 0px;
      background-color: var(--marketplaceColor);
    }

    & > svg {
      position: relative;
      top: 0;
      height: 24px;
      transform: rotate(-90deg);
      width: 24px;
    }
  }

  & .progress_bar__background {
    fill: none;
    stroke: var(--colorGrey100);
    stroke-width: 2;
  }

  & .progress_bar__progress {
    fill: none;
    stroke: var(--marketplaceColor);
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
    stroke-width: 2;
  }
}

.sectionDetailsHead {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .insurance {
    display: inline-flex;
    white-space: nowrap;
    border-radius: var(--borderRadiusMedium);
    background-color: var(--colorGrey300);
    color: var(--colorGrey900);
    font-size: 13px;
    line-height: 120%;
    padding: 6px 10px;
  }
}
